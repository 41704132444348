import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import HomePageComponent from 'style-book/home-page/HomePageComponent';
import SeoComponent from 'style-book/seo/SeoComponent';
import TextComponent from 'style-book/text/TextComponent';

import './TermsAndConditionsScreen.scss';

/* eslint-disable max-lines-per-function */
export default function TermsAndConditionsScreen() {
  return (
    <HomePageComponent>
      <div className="terms-and-condition-screen">
        <div className="terms-and-condition-content">
          <TextComponent variant={TEXT_VARIANTS.H1}>Terms And Conditions</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.H3}>Last Updated: June 16, 2023</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.H3}>Welcome to Goalskeeper.io!</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            These are the terms and conditions for:{' '}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.BULLET}>
            <TextComponent variant={TEXT_VARIANTS.LINK}>https://goalskeeper.io</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`By registering as a user and using the platform, you agree to be bound by these terms and
        conditions and our privacy policy. In these terms and conditions, the words "platform" and
        "website" refer to the Goalskeeper.Io web application, "we", "us", "our" and
        "Goalskeeper.Io" refer to Goalskeeper.Io and "user" and "you" refer to you, the user of
        Goalskeeper.Io.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`The following terms and conditions apply to your use of the platform. This includes mobile and tablet versions, as well as any other version of Goalskeeper.Io accessible via desktop, mobile, tablet, social media, or other devices.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OR OBTAINING ANY SERVICE OR INFORMATION FROM THE PLATFORM.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            1. ACCEPTANCE OF TERMS
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`By registering and using the platform, you agree to be bound by this agreement. If you do not agree to the terms of this agreement, you must not use the platform and must stop using the service immediately. We may modify this agreement from time to time, and such modification will be effective when posted on the website. You agree to be bound by any modifications to these terms and conditions when you use Goalskeeper.Io after such modification is posted; therefore, it is important that you review this agreement regularly.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`You represent and warrant that all registration information you submit is accurate and truthful; and that your use of the platform does not violate any applicable law or regulation. Goalskeeper.Io may, in its sole discretion, refuse to offer the services to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the platform is revoked in such jurisdictions.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`The platform may only be used in compliance with these terms and all applicable local, state, national and international laws, rules and regulations.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`By registering and using the platform, you represent and warrant that you have the full right, power, and authority to enter into this agreement and to fully perform all of your obligations hereunder. You further represent and warrant that you are under no legal disability or contractual restriction that prevents you from entering into this agreement.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>2. ACCOUNT</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`In order to use the platform's functionalities, the user must register and create an account on the platform. If you register on Goalskeeper.Io, you will be required to choose a password, and you may be asked for additional information regarding your account. You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Goalskeeper.Io of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you log out from your account at the end of each session. You may never use another user’s account without prior authorization from Goalskeeper.Io. Goalskeeper.Io will not be liable for any loss or damage arising from your failure to comply with this agreement.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`By providing Goalskeeper.Io with your email address, you consent to our use of your email address to send you notices about the platform. We may also use your email address to send you notifications and other messages, such as changes to service features, news, and special content. If you do not wish to receive these emails, you may opt-out of receiving them by sending us your withdrawal request via the contact information or by using the "unsubscribe" option in the emails. Opting out may prevent you from receiving emails about updates, news or special content.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Users may cancel their accounts at any time and for any reason by following the instructions on the platform or by sending us their request through our contact information. That termination will only result in the deletion of the account and the deletion of all the personal data granted to Goalskeeper.Io.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Goalskeeper.Io reserves the right to terminate your account or your access immediately, with or without notice to you, and without liability to you, if Goalskeeper.Io believes that you have breached any of these terms, furnished Goalskeeper.Io with false or misleading information, or interfered with use of the platform or the service by others.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>3. PAYMENTS</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Some features of the platform may be available through payments. Our order process is conducted by our online reseller `}
            <TextComponent
              variant={TEXT_VARIANTS.LINK}
              href="https://paddle.com/">{`Paddle.com`}</TextComponent>
            {`. Paddle.com is the Merchant of Record for all our orders. Paddle provides all customer service inquiries and handles returns`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`There is a 30 days money back guarantee. No questions aksed. Other than that, when you cancel your subscription, you will be eligible to use the product for the remaining period.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>4. DISCLAIMER</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`By using the platform and the functionalities included in the platform, you accept personal responsibility for the results of the use of the platform and our services. You agree that Goalskeeper.Io does not guarantee any results, benefits or failures as a result of the use of our services and platform. Goalskeeper.Io provides resources and content for educational and informational purposes only. You acknowledge and agree that your ultimate success or failure to use the platform and the content available on the platform will be the result of your own efforts, your particular situation and a number of other circumstances beyond Goalskeeper.Io's control.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            5. THIRD-PARTY MATERIALS.
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`“Third-Party Materials” means any content, images, videos, texts or other material that is owned by a third party, such as stock images, videos and texts. Such Third-Party Materials are subject to the applicable third-party terms and licenses, and may only be used as permitted by such terms and licenses.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            6. LICENSE TO USE THE PLATFORM
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`The ownership rights of the platform and the methods used on the platform belong exclusively to Goalskeeper.Io. Any physical or virtual exposure of the methods used on the platform may result in violations of the intellectual property rights of Goalskeeper.Io.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Goalskeeper.Io gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the platform provided to you by Goalskeeper.Io as part of the services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the services as provided by Goalskeeper.Io, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission.`}
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.PARAGRAPH
            }>{`The user agrees not to use the platform and the services negligently, for fraudulent purposes or in an unlawful manner. Likewise, the user agrees not to partake in any conduct or action that could damage the image, interests or rights of the Goalskeeper.Io platform or third parties.`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`This service prohibits sending of messages, that: (1) Any kind of messages that are cataloged as SPAM. (2) Are harassing, abusive, defamatory, obscene, in bad faith, unethical or otherwise illegal content (3) distribute trojans, viruses or other malicious computer software (4) Any message with political or religious content (5) Any message with obscene or offensive content  (6) Are intending to commit fraud, impersonating other persons, phishing, scams, or related crime (7) distribute intellectual property without ownership or a license to distribute such property (8) Breach, in any way, the terms, and conditions, privacy policy or rules of this platform or the recipients.`}
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.PARAGRAPH
            }>{`Goalskeeper.Io reserves the right to terminate your access immediately, with or without notice, and without liability to you, if Goalskeeper.Io believes that you have violated any of these terms or interfered with the use of the platform or service by others.`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>7. COPYRIGHT</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`All materials on Goalskeeper.Io, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by Goalskeeper.Io or by third parties that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all Materials on Goalskeeper.Io are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without Goalskeeper.Io prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize Goalskeeper.Io or any part of the material for any purpose other than its intended purposes is strictly prohibited.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            8. COPYRIGHT COMPLAINTS
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Goalskeeper.Io will respond to all inquiries, complaints, and claims regarding alleged infringement by breach or violation of the provisions contained in Israeli and international copyright laws. Goalskeeper.Io respects the intellectual property of others and expects users to do the same. If you believe, in good faith, that any material provided on or in connection with the website infringes your copyright or other intellectual property rights, please submit your request to us via our contact information, with the following information: `}
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`A statement that specifically identifies the location of the infringing material, in sufficient detail so that Goalskeeper.Io can find it on the platform.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Your name, address, telephone number, and email address.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, its agents, or by law.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorized to act on its behalf.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner's behalf.`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>9. USER CONTENT</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Some features of the platform may allow users to provide content and information. You retain copyright or any rights you may have in the content and information you provide through the platform. Goalskeeper.Io is not responsible for the accuracy, safety, or legality of the content and information Users provide through the platform. You are solely and exclusively responsible for your content and the consequences of providing such content through the Platform. By providing content and information through the platform, the user grants Goalskeeper.Io a worldwide, non-exclusive, royalty-free, fully paid right and license to host, store, transfer, display, perform, reproduce and modify the user content for the sole purpose of providing the functionalities within the platform.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            10. USER CONTENT REPRESENTATIONS AND WARRANTIES
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Goalskeeper.Io disclaims all liability in connection with user content. You are solely responsible for the content and consequences of providing content through the platform. By providing user content through the platform, you affirm, represent, and warrant that`}
            <TextComponent
              variant={
                TEXT_VARIANTS.PARAGRAPH
              }>{`You own the content you provide through the platform or have the necessary licenses, rights, consents, and permissions to authorize Goalskeeper.Io to host and use such content through the platform.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.PARAGRAPH
              }>{`Your content and the use of your content, as contemplated by these terms, do not, and will not (i) infringe, violate or misappropriate any third party rights, including copyrights, trademarks, patents, trade secrets, moral rights, privacy rights, rights of publicity or any other intellectual property or proprietary rights; (ii) libels, defames, slanders, libels or invades the privacy, publicity or other proprietary rights of any other person; or (iii) causes Goalskeeper.Io to violate any law or regulation.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.PARAGRAPH
              }>{`Your content could not be considered by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.PARAGRAPH
              }>{`Your content does not and will not contain hateful content, threat of physical harm, or harassment.`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>11. PERSONAL DATA</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Any personal information you post or submit in connection with the use of the platform will be used in accordance with our privacy policy. Please see our privacy policy.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            12. PROHIBITED ACTIVITIES
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`The content and information available on the platform (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons, or other material), as well as the infrastructure used to provide such content and information, is proprietary to Goalskeeper.Io or licensed to the Goalskeeper.Io by third parties. For all content other than your content, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software, or services obtained from or through the platform. In addition, the following activities are prohibited:`}
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Use the services or content for any commercial purpose, outside the scope of those commercial purposes explicitly permitted under this agreement and related guidelines as made available by Goalskeeper.Io.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the platform, including but not limited to, using any robot, spider, scraper, or other automated means or any manual process for any purpose not in accordance with this agreement or without our express wrote permission.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Violate the restrictions in any robot exclusion headers on the Services or bypass or circumvent other measures employed to prevent or limit access to the services.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Deep-link to any portion of the platform for any purpose without our express written permission.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`"Frame", "mirror" or otherwise incorporate any part of the platform into any other websites or services without our prior written authorization.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Goalskeeper.Io in connection with the services.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Circumvent, disable or otherwise interfere with security-related features of the platform or features that prevent or restrict the use or copying of any content.`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            13. DISCLAIMER OF WARRANTIES
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Because of the nature of the Internet, Goalskeeper.Io provides and maintains the platform on an "as is", "as available" basis and makes no promise that use of the platform will be uninterrupted or entirely error-free. We are not responsible to you if we are unable to provide our Internet services for any reason beyond our control.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Our platform may from time to time contain links to other websites which are not under the control of and are not maintained by us. These links are provided for your convenience only and we are not responsible for the content of those platforms.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Except as provided above we can give no other warranties, conditions, or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`You will be responsible for any breach of these terms by you and if you use the platform in breach of these terms you will be liable to and will reimburse Goalskeeper.Io for any loss or damage caused as a result.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Goalskeeper.Io will not be liable in any amount for failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen event beyond its reasonable control including without limitation Internet outages, communications outages, fire, flood, war, or act of God.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`These terms do not affect your statutory rights as a consumer which are available to you.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Subject as aforesaid, to the maximum extent permitted by law, Goalskeeper.Io excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect, or consequential loss whether or not such arises out of any problem you notify to Goalskeeper.Io and Goalskeeper.Io shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:`}
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Any incorrect or inaccurate information on the platform.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`The infringement by any person of any Intellectual Property Rights of any third party caused by the use of the services.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Any loss or damage resulting from your use or the inability to use the platform or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances that are beyond our control.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Any loss of profit wasted expenditure, corruption or destruction of data, or any other loss which does not directly result from something we have done wrong.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data, or other property caused by persons accessing or using content from the platform or from transmissions via emails or attachments received from Goalskeeper.Io.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`All representations, warranties, conditions, and other terms which but for this notice would have an effect.`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            14. ELECTRONIC COMMUNICATIONS
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`No responsibility will be accepted by Goalskeeper.Io for failed, partial, or garbled computer transmissions, for any computer, telephone, cable, network, electronic or Internet hardware or software malfunctions, failures, connections, availability, for the acts or omissions of any user, internet accessibility or availability or for traffic congestion or unauthorized human act, including any errors or mistakes.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>15. INDEMNIFICATION</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`You agree to defend and indemnify Goalskeeper.Io from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:`}
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Your breach of this agreement or the documents referenced herein.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Your violation of any law or the rights of a third party.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Your use of the Goalskeeper.Io platform.`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            16. CHANGES AND TERMINATION
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`We may change the platform and these terms at any time, at our sole discretion, and without notice to you. You are responsible for remaining knowledgeable about these terms. Your continued use of the site constitutes your acceptance of any changes to these terms and any changes will supersede all previous versions of the terms. Unless otherwise specified herein, all changes to these terms apply to all users take effect. Furthermore, we may terminate this agreement with you under these terms at any time by notifying you in writing (including by email) or without any warning.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>17. ASSIGNMENT</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`This agreement and any rights and licenses granted hereunder, may not be transferred or assigned by you but may be assigned by Goalskeeper.Io without restriction.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            18. INTEGRATION CLAUSE
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`This agreement together with the privacy policy and any other legal notices published by Goalskeeper.Io, shall constitute the entire agreement between you and Goalskeeper.Io concerning and governs your use of the platform and the services.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>19. DISPUTES</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`The user agrees that any dispute, claim or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation or validity thereof or the use of the platform, shall be resolved by binding arbitration between the user and Goalskeeper.Io, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`In the event a dispute arises in connection with the use of the platform or breach of these terms and conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organization as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.`}
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.PARAGRAPH
            }>{`You agree to initiate a formal dispute proceeding by sending us a communication through our contact information. Goalskeeper.Io may choose to send you a written offer after receiving your initial communication. If we offer and send you a settlement offer and you do not accept the offer, or we are unable to resolve your dispute satisfactorily and you wish to continue with the dispute process, you must initiate the dispute resolution process before an accredited arbitration organization and file a separate Demand for Arbitration. Any award rendered by the arbitration tribunal shall be final and conclusive on the parties.`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.PARAGRAPH
            }>{`To the fullest extent permitted by law, you agree that you will not file, join or participate in any class action lawsuit in connection with any claim, dispute, or controversy that may arise in connection with your use of the platform.`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.PARAGRAPH
            }>{`The courts of Israel shall have jurisdiction over any dispute, controversy, or claim relating to Goalskeeper.Io and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of Israel.`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>20. FINAL PROVISIONS</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`These terms and conditions are governed by the laws of Israel. Use of Goalskeeper.Io platform is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Our performance of these terms is subject to existing laws and legal processes, and nothing contained in these terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our platform or information provided to or gathered by us with respect to such use.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`If any part of these terms is found to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Any rights not expressly granted herein are reserved.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            21. CONTACT INFORMATION
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`If you have questions or concerns about these terms, the products or the service, please contact us through our contact page or via the contact information below:`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.LINK} href="mailto:legal@goalskeeper.io">
            legal@goalskeeper.io
          </TextComponent>
        </div>
      </div>
    </HomePageComponent>
  );
}

export function Head() {
  return (
    <SeoComponent
      title="goalskeeper.io | terms And conditions"
      description="We share the same interests in making your small business succeed. Here are our terms for it"
      canonicalUrl="goalskeeper.io/"
    />
  );
}
